import React  from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Link from 'next/link';

// Dynamic imports
const Slider = dynamic(() => import('react-slick'));
const Images = dynamic(() => import('./Images'));
const Arrow = dynamic(() => import('../Arrow'));

const Slides = ({ carousel }) => {
  const s = require('./slides.module.scss');
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    centerPadding: '20px',
    nextArrow: <Arrow right />,
    prevArrow: <Arrow reverse />
  };
  return (
    <div className={s.Slider}>
      <Slider {...settings}>
        {carousel && carousel.map((item, index) => (
          <div className={s.Container} key={`slides_${index}`}>
            <Link href={`article${item.button.link}`} passHref>
              <a>
                <div className={s.Content}>
                  <div className={s.Category}>
                    <div>
                      <p>{item.date}</p>
                    </div>
                  </div>
                  <div className={s.title}>
                    <h1><span>{item.title}</span></h1>
                  </div>
                </div>
                <Images
                  style={{ width: '1920px', height: '1080px' }}
                  {...item}
                />
              </a>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Slides.propTypes = {
  carousel: PropTypes.array,
};

export default Slides;
